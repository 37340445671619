import React from 'react';
import '../Styles/SuccessPage.css'; // Import the CSS file for styling

function SuccessPage() {
  return (
    <div className="success-container">
      <div className="success-message">
        <h1>Success!</h1>
        <p>Your form has been submitted successfully.</p>
      </div>
    </div>
  );
}

export default SuccessPage;
