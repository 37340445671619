import React, {useState,useEffect} from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'



const backend_url=process.env.REACT_BACKEND_URL

function FormPage2({onPrevious,formData,setFormData}){
    const [errors,setErrors]=useState({})
    const [isValid,setIsValid]=useState(false)
    const navigate=useNavigate()
    const [isLoading,setIsLoading]=useState(false)

    useEffect(()=>{
        validateForm()
    },[formData])

    const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm=()=>{
    const newErrors={}

    if(!formData.candfArea){
        newErrors.candfArea='Please enter C&F area'
    }
    if(!formData.godownSize){
        newErrors.godownSize='Please enter Godown Size'
    }
    if(!formData.logisticsCapacity){
        newErrors.logisticsCapacity='Please enter Logistics capacity'
    }
    if(!formData.investmentCapacity){
        newErrors.investmentCapacity='Please enter investment capacity'
    }
     if(!formData.numberOfDistributorsDealersWholesalerHawker){
        newErrors.numberOfDistributorsDealersWholesalerHawker='Please enter number of Distributors,Dealer,Sub Distributors,Wholesaler,Hawker'
    }
    if(!formData.numberOfRetailers){
        newErrors.numberOfRetailers='Please enter number of Retailers'
    }

    
    setErrors(newErrors)
    setIsValid(Object.keys(newErrors).length===0)
  }
  

const handleSubmit=async()=>{
     const formDataFiles=new FormData()
     formDataFiles.append('shopImage',formData.shopImage)
     formDataFiles.append('shopVideo',formData.shopVideo)
  

    for (const [key, value] of Object.entries(formData)) {
      formDataFiles.append(key, value);
  }
    

    
    
   setIsLoading(true)
    try{ 
       const response=await axios.post(`https://hammerhead-app-aqq5h.ondigitalocean.app/submit-form`,formDataFiles,{
        headers: {
        'Content-Type': 'multipart/form-data',
      },
       })


       if(response.status===200){
        navigate('/success')
       }
       else{
        alert('Form submission failed')
       }

    }
    catch(err){
      alert('Form submission failed')
    }
    finally{
      setIsLoading(false)
    }
  }

  return (
    <div className='form'>
        <h1>C&F Agents</h1>
      <div className='text-question'>
        <p>Desired area for C&F? <span>*</span></p>
        <input type='text' name='candfArea'
               value={formData.candfArea||''}
               onChange={handleChange}
        ></input>
        {errors.candfArea?<div className='error-message'>{errors.candfArea}</div>:''}
      </div>
      <div className='text-question'>
        <p>Godown size (in sq.ft)? <span>*</span></p>
        <input type='text' name='godownSize'
               value={formData.godownSize||''}
               onChange={handleChange}
        ></input>
        {errors.godownSize?<div className='error-message'>{errors.godownSize}</div>:''}
      </div>
      <div className='text-question'>
        <p>Logistics capacity(number of trucks,delivery vans etc)? <span>*</span></p>
        <input type='text' name='logisticsCapacity'
               value={formData.logisticsCapacity||''}
               onChange={handleChange}
        ></input>
        {errors.logisticsCapacity?<div className='error-message'>{errors.logisticsCapacity}</div>:''}
      </div>
      <div className='text-question'>
        <p>Your Investment capacity(monthly)? <span>*</span></p>
        <input type='text' name='investmentCapacity'
               value={formData.investmentCapacity||''}
               onChange={handleChange}
        ></input>
        {errors.investmentCapacity?<div className='error-message'>{errors.investmentCapacity}</div>:''}
      </div>
      <div className='text-question'>
        <p>How many Dealer, Distributor, Sub Distributor, Wholesaler, Hawker do you currently serve? <span>*</span></p>
        <input type='text' name='numberOfDistributorsDealersWholesalerHawker'
               value={formData.numberOfDistributorsDealersWholesalerHawker||''}
               onChange={handleChange}
        ></input>
        {errors.numberOfDistributorsDealersWholesalerHawker?<div className='error-message'>{errors.numberOfDistributorsDealersWholesalerHawker}</div>:""}
      </div>
      
      <div className='text-question'>
        <p>How many Retailers do you supply? <span>*</span></p>
        <input type='text' name='numberOfRetailers'
               value={formData.numberOfRetailers||''}
               onChange={handleChange}
        ></input>
        {errors.numberOfRetailers?<div className='error-message'>{errors.numberOfRetailers}</div>:""}
      </div>
      
      

      <div className='buttons'>
      <button onClick={onPrevious}>Previous</button>
      <button onClick={handleSubmit} disabled={!isValid}>Submit</button>
      {isLoading && <div className="loader"></div>}
      </div>
    </div>
  )

}

export default FormPage2