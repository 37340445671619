// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #ffebee;
   
}



.error-message h1 {
    color: #d32f2f;
    
}

.error-message p {
    color: #c62828;
    
}`, "",{"version":3,"sources":["webpack://./src/Styles/ErrorPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,yBAAyB;;AAE7B;;;;AAIA;IACI,cAAc;;AAElB;;AAEA;IACI,cAAc;;AAElB","sourcesContent":[".error-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    background-color: #ffebee;\n   \n}\n\n\n\n.error-message h1 {\n    color: #d32f2f;\n    \n}\n\n.error-message p {\n    color: #c62828;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
