import { createContext,useState } from "react";


export const AppContext=createContext({
    loginStatus:null

})

const AppProvider=({children})=>{
    const [loginStatus,setLoginStatus]=useState(false)
     

  const value={loginStatus,setLoginStatus}

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    )
}

export default AppProvider