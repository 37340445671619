// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login {
    padding-top: 200px;
    padding-bottom: 80px;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
}

.login-card {
    width: 60%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    border-radius: 10px;
    padding: 50px;
}

.login-card h1 {
    font-family: 'Courier New', Courier, monospace;
    font-weight: 520;
}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-form input {
    height: 50px;
    margin-bottom: 20px;
    width: 90%;
    font-size: large;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 100;
    padding-left: 20px;
}

.login-form button {
    height: 50px;
    width: 200px;
    background-color: #F9C12E;
    border: none;
    font-size: large;
    font-family: 'Courier New', Courier, monospace;
    margin-bottom: 20px;
    border-radius: 5px;
}

.login-form a {
    font-family: 'Courier New', Courier, monospace;
    font-size: medium;
}

.login-form-navigation a {
    margin-right: 50px;
}`, "",{"version":3,"sources":["webpack://./src/Styles/Login.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,oCAAoC;IACpC,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IACV,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,8CAA8C;IAC9C,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,UAAU;IACV,gBAAgB;IAChB,8CAA8C;IAC9C,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,8CAA8C;IAC9C,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,8CAA8C;IAC9C,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".login {\n    padding-top: 200px;\n    padding-bottom: 80px;\n    background-color: rgba(0, 0, 0, 0.1);\n    display: flex;\n    justify-content: center;\n}\n\n.login-card {\n    width: 60%;\n    background-color: #fff;\n    display: flex;\n    flex-direction: column;\n    justify-content: left;\n    align-items: left;\n    border-radius: 10px;\n    padding: 50px;\n}\n\n.login-card h1 {\n    font-family: 'Courier New', Courier, monospace;\n    font-weight: 520;\n}\n\n.login-form {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.login-form input {\n    height: 50px;\n    margin-bottom: 20px;\n    width: 90%;\n    font-size: large;\n    font-family: 'Courier New', Courier, monospace;\n    font-weight: 100;\n    padding-left: 20px;\n}\n\n.login-form button {\n    height: 50px;\n    width: 200px;\n    background-color: #F9C12E;\n    border: none;\n    font-size: large;\n    font-family: 'Courier New', Courier, monospace;\n    margin-bottom: 20px;\n    border-radius: 5px;\n}\n\n.login-form a {\n    font-family: 'Courier New', Courier, monospace;\n    font-size: medium;\n}\n\n.login-form-navigation a {\n    margin-right: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
