// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #e8f5e9;
   
}

.success-message {
    text-align: center;
    background-color: #a5d6a7;
    
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.success-message h1 {
    color: #388e3c;
   
}

.success-message p {
    color: #2e7d32;
    
}`, "",{"version":3,"sources":["webpack://./src/Styles/SuccessPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,yBAAyB;;AAE7B;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;;IAEzB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,cAAc;;AAElB;;AAEA;IACI,cAAc;;AAElB","sourcesContent":[".success-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    background-color: #e8f5e9;\n   \n}\n\n.success-message {\n    text-align: center;\n    background-color: #a5d6a7;\n    \n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n}\n\n.success-message h1 {\n    color: #388e3c;\n   \n}\n\n.success-message p {\n    color: #2e7d32;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
