import React, { useContext, useState } from 'react'
import '../Styles/Login.css'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { AppContext } from '../AppContext'




function Login() {
    const [username,setUsername]=useState('')
    const [password,setPassword]=useState('')
    const [errors,setErrors]=useState({})
    const {loginStatus,setLoginStatus}=useContext(AppContext)
    const [isLoading,setIsLoading]=useState(false)
  
    const navigate=useNavigate()
    const handleLogin=async(event)=>{
        event.preventDefault();
        setErrors({}); // Clear previous errors
        
     setIsLoading(true)
    try {
      const response = await axios.post(`https://hammerhead-app-aqq5h.ondigitalocean.app/login`, {
        username:username,
        password:password,
      })
      
      

      if (response.status === 200) {
        const { token } = response.data; 

        setLoginStatus(true)
        navigate('/admin');
     
         
      } else {
        setErrors({ global: 'Invalid credentials or an error occurred.' });
      }
    } catch (error) {
      
      setErrors({ global:"Invalid credentials or an error occurred." });
    } 

    finally{
      setIsLoading(false)
    }


    }
  return (
    <div className='login'>
        <div className='login-card'>
            <h1>Login in as Admin</h1>
            <form className='login-form' onSubmit={handleLogin}>
                <input
                 type='text'
                 id='username'
                 required
                 placeholder='Username'
                 value={username}
                 onChange={(event)=>(setUsername(event.target.value))}
                 />
                 <input
                  type='password'
                  id='password'
                  required
                  placeholder='Password'
                  value={password}
                  onChange={(event)=>(setPassword(event.target.value))}
                  />
                  {errors.global && <div className='login-erros'>{errors.global}</div>}
                  <button type='submit'>Login</button>
                  {isLoading && <div className="loader"></div>}
            </form>
        </div>
    </div>
  )
}

export default Login