import React  ,{useState} from 'react'
import FormPage1 from './FormPage1';
import FormPage2 from './FormPage2';
import FormPage3 from './FormPage3';
import FormPage4 from './FormPage4';
import FormPage5 from './FormPage5';
import '../Styles/Form.css'
import Landing_image from '../Assets/Landing_image.jpg'

function Form() {

  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({});
  

  const nextPage = () => setCurrentPage(currentPage + 1);
  const previousPage = () => setCurrentPage(1);

  

  return (
    <div className='distributor-form'>
        <img src={Landing_image} alt='poster'></img>
        {currentPage === 1 && (
        <FormPage1
          onNext={nextPage}
          formData={formData}
          setFormData={setFormData}
          
          setCurrentPage={setCurrentPage}
        />
      )}
      {currentPage === 2 && (
        <FormPage2
          onNext={nextPage}
          onPrevious={previousPage}
          formData={formData}
          
          setFormData={setFormData}
        />
      )}
      {currentPage === 3 && (
        <FormPage3
          onNext={nextPage}
          onPrevious={previousPage}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      
      {currentPage === 4 && (
        <FormPage4
          onNext={nextPage}
          onPrevious={previousPage}
          formData={formData}
          setFormData={setFormData}
        />
      )} 
      {currentPage === 5 && (
        <FormPage5
          onPrevious={previousPage}
          formData={formData}
          setFormData={setFormData}
          
        />
      )}
    </div>
    
)
}

export default Form