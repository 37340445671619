
import './App.css';
import Form from './Componets/Form';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import AdminPage from './Componets/AdminPage';
import Login from './Componets/Login';
import AppProvider from './AppContext';
import SuccessPage from './Componets/SuccessPage';
import ErrorPage from './Componets/ErrorPage';
import Form2 from './Componets/Form2';
import Form3 from './Componets/Form3';
import Form4 from './Componets/Form4';



function App() {
  return (
    <div className="App">
      <AppProvider>
      <Router>
        <Routes>
          
          <Route path='/candf-agents' element={<Form/>}></Route>
          <Route path='/super-stockist' element={<Form2/>}/>
          <Route path='/dealer' element={<Form3/>}/>
          <Route path='candf-agents-only' element={<Form4/>}/>
          <Route path='/admin' element={<AdminPage/>}></Route>
          <Route path='/login' element={<Login/>}/>
          <Route path='/success' element={<SuccessPage/>}/>
          <Route path='/error' element={<ErrorPage/>}/>
        </Routes>
      </Router>
      </AppProvider>

    </div>
  );
}

export default App;
